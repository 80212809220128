/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: rgb(110, 21, 14);
  --clr-primary-2: rgb(87, 77, 65);
  --clr-primary-3: rgb(144, 128, 108);
  --clr-primary-4: rgb(178, 167, 151);
  /* primary/main color */
  --clr-primary-5: rgb(178, 167, 151);
  /* lighter shades of primary color */
  --clr-primary-6: rgb(206, 196, 182);
  --clr-primary-7: rgb(231, 225, 218);
  --clr-primary-8: rgb(210, 210, 210);
  --clr-primary-9: rgb(233, 229, 224);
  --clr-primary-10: rgb(235, 247, 255);
  /* darkest grey - used for headings */
  --clr-grey-1: rgb(16, 42, 66);
  --clr-grey-2: rgb(36, 58, 82);
  --clr-grey-3: rgb(50, 77, 103);
  --clr-grey-4: rgb(72, 100, 127);
  /* grey used for paragraphs */
  --clr-grey-5: rgb(97, 125, 152);
  --clr-grey-6: rgb(130, 154, 176);
  --clr-grey-7: rgb(158, 178, 199);
  --clr-grey-8: rgb(188, 204, 220);
  --clr-grey-9: rgb(218, 226, 236);
  --clr-grey-10: rgb(241, 245, 248);
  --clr-white: rgb(255, 255, 255);
  --clr-red-dark: rgb(187, 37, 37);
  --clr-red-light: rgb(230, 107, 107);
  --clr-green-dark: rgb(37, 187, 50);
  --clr-green-light: rgb(107, 230, 117);
  --clr-black: rgb(34, 34, 34);
  --transition: all 0.3s linear;
  --spacing: -0.025rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  --max-width: 1170px;
  --max-width-klein: 890px;
  --fixed-width: 450px;
  --clr-orange-1: rgb(254, 247, 246);
  --clr-orange-2: rgb(254, 236, 231);
  --clr-orange-3: rgb(252, 212, 202);
  --clr-orange-4: rgb(249, 186, 169);
  --clr-orange-5: rgb(245, 133, 102);
  --clr-orange-6: rgb(241, 83, 39);
  --clr-orange-7: rgb(217, 73, 32);
  --clr-orange-8: rgb(146, 49, 22);
  --clr-orange-9: rgb(110, 37, 17);
  --clr-orange-10: rgb(71, 24, 11);
  --mainSpacing: 1.3px;
  --mainTransition: background-color .5s;
}

/*
=============== 
Colors
===============
*/

.primary-back {
  background-color: var(--clr-primary-1) !important;
}

.secondary-back {
  background-color: var(--clr-primary-2) !important;
}

.primary-color {
  color: var(--clr-primary-1) !important;
}

.secondary-color {
  color: var(--clr-primary-2) !important;
}

.blue-color {
  color: var(--clr-grey-1) !important;
}

/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.925rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: var(--clr-primary-1);
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 1000px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section,
.container {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
  justify-content: center;
}

div.section {
  justify-content: space-around;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

.container {
  text-align: center;
  margin-top: 5rem;
}
.container-no-margin {
  text-align: center;
  margin-top: 0rem;
}
.btn {
  display: inline-block;
  background: var(--clr-primary-5);
  color: var(--clr-white);
  padding: 0.25rem 0.75rem;
  border-radius: var(--radius);
  border-color: transparent;
  text-transform: capitalize;
  font-size: 1rem;
  letter-spacing: var(--spacing);
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: var(--transition);
}
.btn:hover {
  background: var(--clr-primary-1);
  color: var(--clr-primary-5);
}
.btn.active {
  background: var(--clr-grey-1);
  color: var(--clr-primary-10);
  border: none;
}
.item {
  background: var(--clr-white);
  display: flex;
  justify-content: space-between;
  max-width: var(--fixed-width);
  margin: 2rem auto;
  align-items: center;
  border-radius: var(--radius);
}
.item button,
.item a {
  background: transparent;
  border-color: transparent;
  color: var(--clr-primary-5);
  letter-spacing: var(--spacing);
  cursor: pointer;
}
.item {
  padding: 1rem 2rem;
}
.item h4 {
  margin-bottom: 0;
}
.item p {
  margin-bottom: 0;
}
.form {
  background: var(--clr-white);
  max-width: var(--fixed-width);
  margin: 0 auto;
  margin-bottom: 4rem;
  padding: 1rem 2rem;
  border-radius: var(--radius);
}
.form input {
  background: var(--clr-grey-10);
  border-color: transparent;
  border-radius: var(--radius);
  padding: 0.25rem 0.5rem;
}
.form-control {
  margin: 0.5rem 0;
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
}
.form button {
  display: inline-block;
  background: var(--clr-black);
  color: var(--clr-white);
  border-color: transparent;
  margin-top: 1rem;
  letter-spacing: var(--spacing);
  padding: 0.15rem 0.25rem;
  text-transform: capitalize;
  border-radius: var(--radius);
  cursor: pointer;
}
.nav-links {
  max-width: var(--fixed-width);
  margin: 0 auto;
  margin-bottom: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.nav-links a {
  color: var(--clr-grey-5);
}
.users {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 3rem auto;
}
.users li {
  width: 100%;
  display: flex;
  align-items: center;
  background: var(--clr-white);
  padding: 1rem 2rem;
  border-radius: var(--radius);
  text-align: left;
}
.users img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}
.users h4 {
  margin-bottom: 0.15rem;
}
.users a {
  color: var(--clr-grey-5);
  text-transform: capitalize;
}

.products {
  margin: 4rem 0;
  display: grid;
  gap: 2rem;
}
@media screen and (min-width: 576px) {
  .products {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
.product {
  background: var(--clr-white);
  border-radius: var(--radius);
}
.product img {
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);

  width: 100%;
  height: 15rem;
  object-fit: cover;
}
.product h4 {
  margin-top: 1rem;
}

.product button {
  margin-bottom: 1rem;
  background: var(--clr-primary-5);
  border-radius: var(--radius);
  border-color: transparent;
  color: var(--clr-white);
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.container h3 {
  line-height: 1.2;
  font-weight: 500;
}
@media screen and (min-width: 992px) {
  .container-menu {
    display: grid;
    grid-template-columns: 250px 1fr;
  }
}
.question {
  padding: 2rem 1.5rem;
  border: 2px solid var(--clr-grey-special);
  margin-bottom: 1rem;
  border-radius: var(--radius);
  box-shadow: var(--light-shadow);
  background-color: white;
}
.question h4 {
  text-transform: none;
  line-height: 1.5;
}
.question p {
  color: var(--clr-grey-3);
  margin-bottom: 0;
}
.question header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.question header h4 {
  margin-bottom: 0;
}

/* 
====== 
Buttons 
======
*/
.btn,
.btn-white,
.btn-primary {
  text-transform: uppercase;
  letter-spacing: var(--mainSpacing);
  color: var(--clr-primary-1);
  border: 2px solid var(--clr-primary-1);
  padding: 0.45rem 0.8rem;
  display: inline-block;
  transition: var(--mainTransition);
  cursor: pointer;
  font-size: 0.8rem;
  background: transparent;
  border-radius: var(--mainBorderRadius);
  display: inline-block;
}
.btn:hover {
  background: var(--clr-primary-1);
  color: var(--clr-primary-10);
}
.btn-white {
  background: transparent;
  color: var(--clr-primary-10);
  border-color: var(--clr-primary-10);
}
.btn-white:hover {
  background: var(--clr-primary-10);
  color: var(--clr-primary-1);
}
.btn-primary {
  background: var(--clr-primary-1);
  color: var(--clr-primary-10);
  border-color: transparent;
}
.btn-primary:hover {
  background: var(--clr-primary-4);
  color: var(--clr-primary-1);
}
.btn-block {
  width: 100%;
  display: block;
  margin: 0 auto;
  box-shadow: var(--clr-primary-10);
  text-align: center;
}
.btn-details {
  padding: 0.25rem 0.4rem;
}
.btn-details:hover {
  background: var(--clr-primary-4);
  border-color: var(--clr-primary-4);
}
.btn-link {
  text-transform: uppercase;
  letter-spacing: var(--mainSpacing);
  color: var(--clr-primary-1);
  border: 0px solid var(--clr-primary-1);
  padding: 0.45rem 0.8rem;
  display: inline-block;
  transition: var(--mainTransition);
  cursor: pointer;
  font-size: 0.8rem;
  background: transparent;
  border-radius: var(--mainBorderRadius);
}
.btn-link:hover {
  text-decoration: underline;
  color: var(--clr-primary-1);
}
.btn-round {
  text-transform: uppercase;
  letter-spacing: var(--mainSpacing);
  color: var(--clr-primary-1);
  border: 2px solid var(--clr-primary-1);
  transition: var(--mainTransition);
  cursor: pointer;
  font-size: 1.5rem;
  background: transparent;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  padding-top: 0.25rem;
  display: inline-block;
  text-align: center;
}
.btn-round:hover {
  background: var(--clr-primary-1);
  color: var(--clr-primary-10);
}
.btn-container {
  margin-bottom: 4rem;
  margin-top: -2.7rem;
  display: flex;
  justify-content: center;
}
.filter-btn {
  background: transparent;
  border-color: transparent;
  font-size: 1.1rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: -0.1px;
  padding: 5rem 0 0 0;
  color: var(--clr-primary-1);
  cursor: pointer;
  transition: var(--transition);
  border-radius: var(--radius);
}
.filter-btn:hover {
  background: var(--clr-gold);
  color: var(--clr-primary-2);
}
.filter-btn:focus {
  font-weight: bold;
}
.section-center {
  margin: 0 auto;
  max-width: 1170px;
  display: grid;
  gap: 3rem 2rem;
  justify-items: center;
  margin-bottom: 2rem;
}
.menu-item {
  display: block;
  /*max-width: 25rem;*/
  width: 12.75rem;
  border: 2px solid var(--clr-grey-9);
  border-radius: 10px;
  padding: 1rem 0rem 0rem 0rem;
}

.menu-item img {
  border-radius: 0px 0px 8px 8px;
  margin-bottom: -0.25rem;
}

.menu-item h4 {
  font-size: 1.15rem;
  color: var(--clr-grey-3);
}

/* section */
.section-stepper {
  width: 30vw !important;
  margin: 0 auto;
  max-width: var(--max-width) !important;
}

.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width-klein);
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}
.menu {
  padding: 5rem 0;
}
.btn-wrapper {
  background: transparent;
  border: transparent;
  border-radius: 7px;
  margin: 0.3rem 0.3rem 2rem 0.3rem;
  transition: var(--mainTransition);
  cursor: pointer;
  justify-content: space-evenly;
}
.btn-wrapper:hover {
  background: var(--clr-grey-10);
}

.isActive{
  background: var(--clr-grey-9) !important;
}

/*Modal*/


.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 3rem 4rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 1.5) calc(var(--bs-modal-header-padding-x) * 1.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.75em 0.75em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
  font-size: 2rem;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(69, 130, 236, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  background-color: transparent;
  color: var(--clr-primary-1);
  line-height: 0;
  padding: 0;
  margin: 0;
  text-align: left;
  color: red;
  display: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
  color: green;
}


.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--clr-primary-2), var(--bs-bg-opacity)) !important;
}

.badge {
  --bs-badge-padding-x: 1.2em;
  --bs-badge-padding-y: 0.6em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 400;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--clr-primary-2);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.btn-light {
  padding: 6px 8px 0px 8px;
  margin: 0;
  color: var(--clr-grey-1);
  background: none;
  font-size: 1.5rem;
  border: 1px solid var(--clr-primary-2);
}
.btn-light:hover {
  padding: 6px 8px 0px 8px;
  margin: 0;
  color: var(--clr-grey-5);
  background: none;
  font-size: 1.5rem;
  border: 1px solid var(--clr-primary-2);
}
.cart-quantity {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 3;
  background-color: var(--clr-grey-10)
}
.quantity-wrapper{
  margin-top: 4rem;
}

.price{
  font-size: 1.5rem
}

footer {
  padding-bottom: 3rem;
}


.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: 2rem;
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: #1a2530;
  --bs-alert-bg: #d5d8dc;
  --bs-alert-border-color: #c0c5cb;
}
.alert-primary .alert-link {
  color: #151e26;
}

.alert-secondary {
  --bs-alert-color: #596364;
  --bs-alert-bg: #eaeded;
  --bs-alert-border-color: #dfe4e4;
}
.alert-secondary .alert-link {
  color: #474f50;
}

.alert-success {
  --bs-alert-color: #0e715e;
  --bs-alert-bg: #d1f2eb;
  --bs-alert-border-color: #baebe1;
}
.alert-success .alert-link {
  color: #0b5a4b;
}

.alert-info {
  --bs-alert-color: #1f5b83;
  --bs-alert-bg: #d6eaf8;
  --bs-alert-border-color: #c2e0f4;
}
.alert-info .alert-link {
  color: #194969;
}

.alert-warning {
  --bs-alert-color: #925e0b;
  --bs-alert-bg: #fdebd0;
  --bs-alert-border-color: #fbe1b8;
}
.alert-warning .alert-link {
  color: #754b09;
}

.alert-danger {
  --bs-alert-color: #8b2e24;
  --bs-alert-bg: #fadbd8;
  --bs-alert-border-color: #f8c9c5;
}
.alert-danger .alert-link {
  color: #6f251d;
}

.alert-light {
  --bs-alert-color: #8e9091;
  --bs-alert-bg: #fbfcfc;
  --bs-alert-border-color: #f9fbfb;
}
.alert-light .alert-link {
  color: #727374;
}

.alert-dark {
  --bs-alert-color: #4a5353;
  --bs-alert-bg: #e5e8e8;
  --bs-alert-border-color: #d7dcdc;
}
.alert-dark .alert-link {
  color: #3b4242;
}

.selection {
  margin-top: 1.75rem;
  margin-bottom: 0.75rem;
  padding-top: 1.75rem;
  padding-bottom: 1rem;
  font-size: 1.1rem;
  background-color: var(--clr-grey-10);
}

.padd-top {
  padding-top: 3rem;
  max-width: 90%;
}

caption {
  font-size: 0.8rem;
  color: var(--clr-grey-6);
  display: block;
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
}

a {

}