:root {
    /* dark shades of primary color*/
    --clr-primary-1: rgb(110, 21, 14);
    --clr-primary-2: rgb(87, 77, 65);
    --clr-primary-3: rgb(144, 128, 108);
    --clr-primary-4: rgb(178, 167, 151);
    /* primary/main color */
    --clr-primary-5: rgb(178, 167, 151);
    /* lighter shades of primary color */
    --clr-primary-6: rgb(206, 196, 182);
    --clr-primary-7: rgb(231, 225, 218);
    --clr-primary-8: rgb(210, 210, 210);
    --clr-primary-9: rgb(233, 229, 224);
    --clr-primary-10: rgb(235, 247, 255);
    /* darkest grey - used for headings */
    --clr-grey-1: rgb(16, 42, 66);
    --clr-grey-2: rgb(36, 58, 82);
    --clr-grey-3: rgb(50, 77, 103);
    --clr-grey-4: rgb(72, 100, 127);
    /* grey used for paragraphs */
    --clr-grey-5: rgb(97, 125, 152);
    --clr-grey-6: rgb(130, 154, 176);
    --clr-grey-7: rgb(158, 178, 199);
    --clr-grey-8: rgb(188, 204, 220);
    --clr-grey-9: rgb(218, 226, 236);
    --clr-grey-10: rgb(241, 245, 248);
    --clr-white: rgb(255, 255, 255);
    --clr-red-dark: rgb(187, 37, 37);
    --clr-red-light: rgb(230, 107, 107);
    --clr-green-dark: rgb(37, 187, 50);
    --clr-green-light: rgb(107, 230, 117);
    --clr-black: rgb(34, 34, 34);
    --transition: all 0.3s linear;
    --spacing: -0.025rem;
    --radius: 0.25rem;
    --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
    --max-width: 1170px;
    --max-width-klein: 890px;
    --fixed-width: 450px;
    --clr-orange-1: rgb(254, 247, 246);
    --clr-orange-2: rgb(254, 236, 231);
    --clr-orange-3: rgb(252, 212, 202);
    --clr-orange-4: rgb(249, 186, 169);
    --clr-orange-5: rgb(245, 133, 102);
    --clr-orange-6: rgb(241, 83, 39);
    --clr-orange-7: rgb(217, 73, 32);
    --clr-orange-8: rgb(146, 49, 22);
    --clr-orange-9: rgb(110, 37, 17);
    --clr-orange-10: rgb(71, 24, 11);
    --mainSpacing: 1.3px;
    --mainTransition: background-color .5s;
  }
  


.custom-header-container {
 display: flex;   
 justify-content: space-between;   
 margin-top: 30px;
}

.round-corner {
    border-radius: 50px;
    font-size: 1rem;
    letter-spacing: 1px;
    padding-left: 1.5rem;
    padding-right: 1rem;
}

.card {
    padding: 20px;
    margin: 30px 10px 30px 10px;
    border: 1px solid #8f8f8f;
    display: inline-block;
}

.padding-bottom-big {
    padding-bottom: 2em;
}

.padding-bottom-medium {
    padding-bottom: 1em;
}

.padding-bottom-small {
    padding-bottom: 0.75em;
}

.speisen-info {
    position: relative;
    top: -0.4rem;
}

.alternative-empfehlung {
    display: block;
    width: 15rem;
    font-size: 0.9rem;
}

.comma-item + .comma-item::before {
    display: inline-block;
    white-space: pre;
    content: ", ";
  }

.border-light {
    background: none;
    border: 1px solid var(--clr-primary-1);
    color: var(--clr-primary-1);
}

.aktion {
    fill: var(--clr-grey-2);
    width: 5rem;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}

.topwein-icon-container {
    display: grid;
    justify-content: right;
    height: 0;
    color: var(--clr-primary-1);
    position: relative;
    z-index: 1;
}

.topwein-icon {
    font-size: 1.4rem;

}

.topwein-text {
    font-size: 1rem;
    color: var(--clr-primary-1);
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 2px;
}

.border-light:hover {
    background: var(--clr-grey-9);
}

.btn-big {
    font-size: 1.1rem;
    padding: 0.75rem
}

.primary-color {
    color: var(--clr-primary-1)
}


.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
color: white;
background-color: var(--clr-primary-1);
border-color: var(--clr-primary-1);
}

.btn-check + .btn:hover {
color: var(--clr-grey-1);
background-color: var(--clr-grey-1);
border-color: var(--clr-grey-1);
color: white;
}

.more-info {
    padding-top: 1rem;
    display: block;
}

.range-label {
    font-size: 0.75rem;
    padding-left: 2.8rem;
    padding-right: 2.8rem;
    cursor: pointer;
}

input.form-range {
    -webkit-appearance: none;
    width: 320px;
    height: 10px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    background-image: linear-gradient(var(--clr-grey-8), var(--clr-grey-8));
    background-repeat: no-repeat;
    margin-top: 1rem;
    cursor: pointer;
}

/* Input Thumb */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--clr-primary-1);
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }
  
  .hero-paragraph {
    font-size: 1.2rem;
    padding-bottom: 1.5rem;
    width: 100%;
  }

  .icon-big {
    font-size: 2.5rem;
    color: var(--clr-primary-1);
  }

  .page-100 {
    min-height: calc(100vh - 50rem);
  }


  .floating-button {
    position: fixed;
    right: 0rem;
    bottom: 4rem;
    background-color: var(--clr-grey-8);
    border-top-left-radius: 4rem;
    border-bottom-left-radius: 4rem;
    padding: 0.5rem;
    display: flex;
    transition: transform 1.0s;
    transform: translateX(0px);
    max-height: 5.6rem;
  }

  .floating-button.is-shown {
    transform: translateX(300px);
  }

  .floating-img {
    width: 75px;
    height: 75px;
    border-radius: 100%;
    padding: 0;
  }

  .floating-text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .floating-text-wrapper.is-shown {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .floating-text {
    color: var(--clr-grey-1);
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
  }


/* Optimisation for mobile view */

@media only screen and (max-width: 600px) {
      
    .custom-header-container {
        display: block;
        margin-bottom: -3rem;
    }

    .img-fluid {
        width: 80%;
    }

    .section-stepper {
        display: block;
        padding: 0;
        margin-top: 6rem;
        margin-bottom: -5rem;
        width: 100% !important;
    }

    .section {
        width: 75vw;
    }

    .question header {
        display: block;
    }

    label.btn.btn-outline-danger {
        margin: 5px 5px 5px 5px;
    }

    header p {
        padding-bottom: 1.4rem;
    }

    header button.btn-round {
        margin-top: 2rem;
    }

    .modal-body > .container {
        margin-top: 1rem;
    }

    .modal-body > .container > .row {
        display: block;
    }

    .modal-body > .container > .row > .col > h3 {
        margin-top: 2rem;
        padding: 0;
        font-size: 1.6rem;
    }

    .modal-body > .container > .row > .col > div > ul > li > label {
        font-size: 1.1rem;
        margin: 0.65rem;
    }

    .modal-header {
        padding: 1.5rem 1.5rem 0rem 2rem;
    }

    .range-label {
        padding: 6%;
        margin: 0;
    }

    input.form-range {
        -webkit-appearance: none;
        width: 90%;
        height: 10px;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 5px;
        background-image: linear-gradient(var(--clr-grey-8), var(--clr-grey-8));
        background-repeat: no-repeat;
        margin-top: 1rem;
        cursor: pointer;
    }

    .topwein-icon-container {
        color: var(--clr-primary-1);
        display: block;
        height: auto;
        padding-bottom: 1.6rem;
    }

}